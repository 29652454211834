.dialog-breakable {
  position: relative;
  overflow: hidden;
}

.pieces {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Prevent interaction while breaking */
}

.piece {
  position: absolute;
  width: 50%;
  height: 50%;
  background-color: #fff; /* Match your dialog's background */
  border: 1px solid #ccc;
  transition: transform 0.5s ease, opacity 0.5s ease;
  pointer-events: auto; /* Allow button interaction */
}

.piece-1 {
  top: 0;
  left: 0;
}

.piece-2 {
  top: 0;
  right: 0;
}

.piece-3 {
  bottom: 0;
  left: 0;
}

.piece-4 {
  bottom: 0;
  right: 0;
}

.dialog-breakable.break .piece {
  opacity: 0;
}

.dialog-breakable.break .piece-1 {
  transform: translate(-100%, 100%) rotate(5deg);
}

.dialog-breakable.break .piece-2 {
  transform: translate(100%, 100%) rotate(-5deg);
}

.dialog-breakable.break .piece-3 {
  transform: translate(-100%, -100%) rotate(10deg);
}

.dialog-breakable.break .piece-4 {
  transform: translate(100%, -100%) rotate(-10deg);
}

.chatbot__button {
  z-index: 100;
  position: fixed;
  bottom: 35px;
  right: 40px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #227ebb;
  color: #f3f7f8;
  border: none;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  animation: bounce 2s infinite ease-in-out, pulse 2s infinite ease-in-out;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); 
  }
  40% {
    transform: translateY(-10px); 
  }
  60% {
    transform: translateY(-5px); 
  }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1); 
  }
  50% {
    transform: scale(1.1); 
  }
}

@keyframes bounceAndPulse {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0) scale(1); 
  }
  40% {
    transform: translateY(-10px) scale(1.1); 
  }
  60% {
    transform: translateY(-5px) scale(1.05); 
  }
}

.chatbot__button {
  animation: bounceAndPulse 2s infinite ease-in-out; 
}
