.timer-wrapper {
  display: flex;
  justify-content: center;
}

.timer {
  font-family: 'LilGrotesk', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.timer .background {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: white;
  animation: growAndFade 1s infinite ease-out;
}

@keyframes growAndFade {
  0% {
    opacity: 0.25;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.value {
  font-size: 40px;
  color: #ffffff;
  z-index: 10; /* Ensure the number is above the background */
}

.info {
  max-width: 360px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 16px;
}
