
  @keyframes zoomInOut {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0) scale(1);
    }
    40% {
      transform: translateY(-3px) scale(1.1);
    }
    60% {
      transform: translateY(-2px) scale(1.05);
    }
  }
  