.eoi-page {
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
}

.logo {
    width: 100%;
    display: flex;
    justify-content: center;
  }

.byte-logo-subtitle {
  position: relative;
  bottom: 10px;
}

.byte-text-icon {
    position: relative;
    background: radial-gradient(circle at 50% 50%, rgba(123, 255, 0, 0.75) 0%, rgba(0, 99, 48, 0.75) 100%);
    border-radius: 50%;
    width: 40px;  
    height: 40px;  
    padding: 2px;
    box-sizing: border-box; 
    display: inline-block;
    margin-left: 8px;
  }

.byte-icon {
  animation: bounceAndPulse 2s infinite ease-in-out;
}

.text-bold{
  font-weight: 600;
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.next-button{
    position: relative;
    float: right;
    bottom: 196px;
    left: 24px;
    color: white;
    text-decoration: underline;
    cursor: pointer;
}

.next-button1 {
    position: relative;
    float: right;
    bottom: 125px;
    left: 17px;
    color: white;
    text-decoration: underline;
    font-size: 16px;
    padding: 10px 20px; 
    cursor: pointer;
}

@media (max-width: 768px) {
    .next-button1 {
    position: static;
    float: none;
    // bottom: 171px;
    // left: 30px;
    color: white;
    text-decoration: underline;
    font-size: 16px;
    padding: 10px 20px 10px 56px; 
    text-align: center;
    }
}

.left-size-text{
    position: relative;
    bottom: 151px;
    right: 209px;
    color: white;
    width: 235px;
}



.typewriter{
    border-right: .04em solid orange;
    font-family: 'LilGrotesk', sans-serif;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    -webkit-animation: type 2s steps(40, end);
    animation: type 2s steps(40, end);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .drag-and-drop-page{
    overflow: hidden;
  }
  
  
  @keyframes type {
    0% {
      width: 0;
    }
    99.9% {
      border-right: .15em solid orange;
    }
    100% {
      border: none;
    }
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes showTopText {
    0% {
      transform: translate3d(0, 100%, 0); 
    }
    30% {
      transform: translate3d(0, 20%, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }

.animated-text {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  opacity: 0;
  animation: fadeIn 3s forwards;
}

.word {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
  animation: slideIn 3s ease-out forwards;
}

@keyframes slideIn {
  0% {
    transform: translate3d(0, 100%, 0);
    opacity: 0;
  }
  60% {
    transform: translate3d(0, -20%, 0); 
    opacity: 0.8;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}


.animated-text .word:nth-child(1) {
  animation-delay: 0s;
}

.animated-text .word:nth-child(2) {
  animation-delay: 0.3s; 
}

.animated-text .word:nth-child(3) {
  animation-delay: 0.6s; 
}

.highlight {
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;
  background: radial-gradient(circle, rgba(123, 255, 0, 0.75) 0%, rgba(0, 99, 48, 0.75) 100%);
  background-clip: text;
  color: transparent;
  display: inline;
  animation: pulse 2s infinite;
  text-shadow: 0 0 5px radial-gradient(circle, rgba(123, 255, 0, 0.75) 0%, rgba(0, 99, 48, 0.75) 100%);
}

@keyframes pulse {
  0% {
      text-shadow: 0 0 5px rgba(50, 205, 50, 0.7), 0 0 10px rgba(0, 100, 0, 0.7), 0 0 15px rgba(0, 255, 0, 0.7);
  }
  50% {
      text-shadow: 0 0 10px rgba(50, 205, 50, 1), 0 0 15px rgba(0, 100, 0, 1), 0 0 20px rgba(0, 255, 0, 1);
  }
  100% {
      text-shadow: 0 0 5px rgba(50, 205, 50, 0.7), 0 0 10px rgba(0, 100, 0, 0.7), 0 0 15px rgba(0, 255, 0, 0.7);
  }
}

.dot {
  display: inline-block;
  width: 7px;
  height: 7px;
  margin: 0 2px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(123, 255, 0, 0.75) 0%, rgba(0, 99, 48, 0.75) 100%);
  opacity: 0;
  animation: dotCycle 3s infinite;
}

/* Delay the appearance of each dot */
.dot:nth-child(1) {
  animation-delay: 1s;
}
.dot:nth-child(2) {
  animation-delay: 1.4s;
}
.dot:nth-child(3) {
  animation-delay: 1.6s;
}

/* Animation for appearing and disappearing dots */
@keyframes dotCycle {
  0%, 100% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
  66% {
    opacity: 1;
  }
}

.cursor {
  display: inline-block;
  font-weight: bold;
  animation: blink 0.8s infinite step-start;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
