.placeholder-container {
    display: flex;
    align-items: center;
    position: relative;
    left: 10px; /* Adjust based on your layout */
  }
  
  .hand-icon {
    font-size: 24px; /* Adjust size */
    animation: bounce 1s infinite;
    background-color:'#227ebb'
  }

  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateX(-10px);
    }
    60% {
      transform: translateX(-5px);
    }
  }
  
  