@font-face {
    font-family: 'LilGrotesk';
    src: url('../../../assets/fonts/LilGrotesk-Regular.woff2') format('woff2'),
         url('../../../assets/fonts/LilGrotesk-Regular.ttf') format('truetype');
    font-weight: normal; /* Regular weight */
    font-style: normal;
  }
  
  /* Bold weight */
  @font-face {
    font-family: 'LilGrotesk';
    src: url('../../../assets/fonts/LilGrotesk-Bold.woff2') format('woff2'),
         url('../../../assets/fonts/LilGrotesk-Bold.ttf') format('truetype');
    font-weight: bold; /* Bold weight */
    font-style: normal;
  }
  
  /* Black weight */
  @font-face {
    font-family: 'LilGrotesk';
    src: url('../../../assets/fonts/LilGrotesk-Black.woff2') format('woff2'),
         url('../../../assets/fonts/LilGrotesk-Black.ttf') format('truetype');
    font-weight: 900; /* Black weight */
    font-style: normal;
  }
  
  /* Medium weight */
  @font-face {
    font-family: 'LilGrotesk';
    src: url('../../../assets/fonts/LilGrotesk-Medium.woff2') format('woff2'),
         url('../../../assets/fonts/LilGrotesk-Medium.ttf') format('truetype');
    font-weight: 500; /* Medium weight */
    font-style: normal;
  }
  
  /* Thin weight */
  @font-face {
    font-family: 'LilGrotesk';
    src: url('../../../assets/fonts/LilGrotesk-Thin.woff2') format('woff2'),
         url('../../../assets/fonts/LilGrotesk-Thin.ttf') format('truetype');
    font-weight: 100; /* Thin weight */
    font-style: normal;
  }
  
  /* Light weight */
  @font-face {
    font-family: 'LilGrotesk';
    src: url('../../../assets/fonts/LilGrotesk-Light.woff2') format('woff2'),
         url('../../../assets/fonts/LilGrotesk-Light.ttf') format('truetype');
    font-weight: 300; /* Light weight */
    font-style: normal;
  }
  