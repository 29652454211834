
body {
  font-family: 'LilGrotesk', sans-serif; /* Apply the font globally */
  background-color: black;
}

h1 {
  font-family: 'LilGrotesk', sans-serif;
  font-weight: normal; /* Regular weight */
}

h2 {
  font-family: 'LilGrotesk', sans-serif;
  font-weight: bold; /* Bold weight */
}

h3 {
  font-family: 'LilGrotesk', sans-serif;
  font-weight: 900; /* Black weight */
}

h4 {
  font-family: 'LilGrotesk', sans-serif;
  font-weight: 500; /* Medium weight */
}

h5 {
  font-family: 'LilGrotesk', sans-serif;
  font-weight: 100; /* Thin weight */
}

/* Light weight */
p {
  font-family: 'LilGrotesk', sans-serif;
  font-weight: 300;  /* Light weight */
}



body {
  margin: 0;
  font-family: 'LilGrotesk', sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
